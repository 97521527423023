body {
    min-height: 100vh;
}

.text-gradient {
    background: -webkit-linear-gradient(to right, #6371de, #bb8fc3, #fba5ae);
    background: -o-linear-gradient(to right, #6371de, #bb8fc3, #fba5ae);
    background: -moz-linear-gradient(to right, #6371de, #bb8fc3, #fba5ae);
    background: linear-gradient(to right, #6371de, #bb8fc3, #fba5ae);

    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;

    -webkit-text-fill-color: transparent;
    color: transparent;
}

@media only screen and (max-width: 768px) {
    .text-center-mobile {
      text-align: center;
    }

    .margin-mobile {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }
}
  
@keyframes fadeInAnim {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeOutAnim {
    from { opacity: 1; }
    to { opacity: 0; }
}

.psh-logo {
    width: 100px;
    top: 20px;
    left: 40px;
    position: absolute;
    @media screen and (max-width: 450px) {
        width: 50px !important;
        left: 20px;
    }
    @media screen and (min-width: 450px) and (max-width: 576px) {
        width: 60px !important;
        left: 30px;
    }
    @media screen and (min-width: 576px) and (max-width: 768px) {
        width: 80px !important;
        left: 30px;
    }
    @media screen and (min-width: 768px) and (max-width: 830px) {
        width: 90px !important;
        left: 30px;
    }
    // @media screen and (min-width: 830px) and (max-width: 992px) {
    //     max-width: 760px !important;
    // }
}

.container {
    @media screen and (max-width: 576px) {
        max-width: 480px !important;
    }
    @media screen and (min-width: 576px) and (max-width: 768px) {
        max-width: 540px !important;
    }
    @media screen and (min-width: 768px) and (max-width: 830px) {
        max-width: 700px !important;
    }
    @media screen and (min-width: 830px) and (max-width: 992px) {
        max-width: 760px !important;
    }
}

.bg-home {
    height: 340px; 
    padding-top: 80px;
    background-size: cover;
    align-self: center;
    position: relative;
    background-position: center center;

    @media screen and (max-width: 450px) {
        padding-top: 130px !important;
        height: 560px !important;
    }
    @media screen and (min-width: 450px) and (max-width: 576px) {
        padding-top: 130px !important;
        height: 540px !important;
    }
    @media screen and (min-width: 576px) and (max-width: 768px) {
        padding-top: 110px !important;
        height: 440px !important;
    }
    @media screen and (min-width: 768px) and (max-width: 830px) {
       height: 420px !important;
    }
    @media screen and (min-width: 830px) and (max-width: 992px) {
        height: 380px !important;
    }
    @media screen and (min-width: 992px) and (max-width: 1200px) {
        height: 400px !important;
    }
}

.bg-questions {
    padding-top: 80px;
    padding-bottom: 40px;
    background-size: cover;
    align-self: center;
    position: relative;
    background-position: center center;

    .discord-link {
        top: 20px;
        right: 30px;
        position: absolute;
        display: flex;
        p {
            position: relative;
            top: 4px;
            font-size: 14px;
            color: black;
        }

        img {
            margin-left: 6px;
            width: 32px;
            height: 36px;
            display: inline-flex;
        }
    }
    .omw-link {
        top: 20px;
        right: 46px;
        position: absolute;
        display: flex;
        p {
            position: relative;
            top: 4px;
            font-size: 14px;
            color: black;
        }

        img {
            // margin-left: 6px;
            // width: 32px;
            // height: 36px;
            // display: inline-flex;
            width: 32px;
            border-radius: 2px;
            box-shadow: 2px 2px 0 #212121;
            transition: box-shadow 0.1s, transform 0.1s ease-in-out; // added transform
            margin-left: 6px;
            &:hover {
                transform: translate(2px, 2px);
                box-shadow: 0px 0px 0 #000 !important;
              }
        }
    }
}

.title-heading {
    line-height: 26px;
    top: 50px;

    @media screen and (max-width: 768px) {
        position: relative;
        top: -40px;
        // position: inherit;
        // display: block;
        // padding-bottom: 30px;
    }

    // @media screen and (min-width: 768px) and (max-width: 992px) {
    //     position: fixed;
    //     right: 6%;
    //     top: 50%;
    //     left: 66%;
    //     transform: translate(0%, -50%);
    // }

    // @media screen and (min-width: 992px) and (max-width: 1200px) {
    //     position: fixed;
    //     right: 10%;
    //     top: 50%;
    //     left: 60%;
    //     transform: translate(0%, -50%);
    // }

    // @media screen and (min-width: 1200px) {
    //     position: fixed;
    //     right: 14%;
    //     top: 50%;
    //     left: 55%;
    //     transform: translate(0%, -50%);
    // }



    .heading {
        font-size: 45px !important;
        letter-spacing: 1px;
        @media (max-width: 768px) {
            font-size: 35px !important;
        }
    }
    .para-desc {
        font-size: 18px;
    }
}

.cta-btn {
    font-weight: 500;
    // background-color: #fbbf24;
    letter-spacing: -0px;
    width: 100%;

    line-height: 1.1em;
    border: 1px solid #212121;
    padding: 10px 20px;
    font-size: 1.3em;
    box-shadow: 3px 3px 0 #212121;
    border-radius: 5px;
    background: #ffb065;
    color: #212121;
    display: inline-block;
    cursor: pointer;
    position: relative;
    outline: none !important;

    transition: box-shadow 0.1s, transform 0.1s ease-in-out; // added transform
  
    &:hover {
      transform: translate(2px, 2px);
      box-shadow: 1px 1px 0 #000 !important;
      background: #e8a262;
      color: #212121;
      outline: none !important;
      border: 1px solid #212121;
    //   transition: box-shadow 0.3s, top 0.3s, left 0.3s ease-in-out;
    }
    &:disabled {
        background: #ffb065;
    }
    &:focus {
        outline: none !important;
        box-shadow: 1px 1px 0 #000 !important;
    }
    &:active {
        background-color: #ffb065 !important;
        color: #212121 !important;
        outline: none !important;
        box-shadow: 1px 1px 0 #000 !important;
    }
    &:focus-visible {
        background-color: #ffb065 !important;
        color: #212121 !important;
        border: 1px solid #212121 !important;
        box-shadow: 3px 3px 0 #212121 !important;
    }

    @media screen and (max-width: 576px) {
        // max-width: 480px !important;
        margin-top: 24px;
    }
    // @media screen and (min-width: 576px) and (max-width: 768px) {
    //     max-width: 540px !important;
    // }
    // @media screen and (min-width: 768px) and (max-width: 830px) {
    //     max-width: 700px !important;
    // }
    @media screen and (min-width: 830px) and (max-width: 992px) {
        max-width: 760px !important;
    }
    // @media screen and (min-width: 992px) and (max-width: 1280) {
    //     max-width: 760px !important;
    // }
}
.cta-input {
    text-align: center;
    border: 1px solid #212121;
    box-shadow: 3px 3px 0 #212121;
    padding: 8px 15px;
    width: 100%;
    border-radius: 20px;
    margin: 0 auto;
    display: block;
    font-size: 1.2em;
    outline: none;
    font-weight: 300;
}

.sec-hero {
    background-color: #ffdf3c;
    .header {
        font-weight: 700;
        font-size: 48px;
        letter-spacing: -2px;
        line-height: 1;
        color: #212121;
        display: block;
        transition: all 0.2s ease-in-out;
        @media screen and (max-width: 576px) {
            font-size: 34px;
            margin-top: 12px;
            margin-bottom: 28px !important;
        }
        @media screen and (min-width: 576px) and (max-width: 768px) {
            font-size: 34px;
        }
        @media screen and (min-width: 768px) and (max-width: 992px) {
            font-size: 42px;
        }
        @media screen and (min-width: 992px) and (max-width: 1200px) {
            font-size: 48px;
        }
    }
    .subtext {
        font-weight: 300;
        font-size: 22px;
        letter-spacing: -1px;
        color: #212121;
        display: block;
        transition: all 0.2s ease-in-out;
        @media screen and (max-width: 576px) {
            font-size: 20px;
        }
        @media screen and (min-width: 576px) and (max-width: 768px) {
            font-size: 20px;
        }
        @media screen and (min-width: 768px) and (max-width: 992px) {
            font-size: 22px;
        }
        @media screen and (min-width: 992px) and (max-width: 1200px) {
            font-size: 22px;
        }
    }
    .subcta-text {
        font-weight: 300;
        font-size: 16px;
        margin-top: 10px;
        letter-spacing: -0.5px;
        color: #212121;
        display: block;
        transition: all 0.2s ease-in-out;
        @media screen and (max-width: 576px) {
            margin-top: 20px;
            font-size: 14px;
        }
    }
    // .btn {
    //     line-height: 1.5em;
    //     border: 2px solid #000;
    //     padding: 20px 45px;
    //     font-size: 1.5em;
    //     box-shadow: 3px 3px 0 #000;
    //     border-radius: 5px;
    //     background: #69cf95;
    //     color: #000;
    //     display: inline-block;
    //     cursor: pointer;
    //     &:hover {
    //         top: 4px;
    //         left: 4px;
    //         box-shadow: 1px 1px 0 #000!important;
    //         transition-property: box-shadow,top,left;
    //         transition-duration: .3s;
    //         transition-timing-function: ease-in-out;
    //     }
    // }

    .image {
        width: 100%;
        display: block;
        position: absolute;
        top: -200px;
    }
    .bottom-gradient {
        position: absolute;
        bottom: 0px;
        width: 100%;
        background-color: pink;
        height: 100px;
        pointer-events: none;

        background: -webkit-linear-gradient(transparent, white);
        background: -o-linear-gradient(transparent, white);
        background: -moz-linear-gradient(transparent, white);
        background: linear-gradient(transparent, white);
    } 
    .login {
        font-weight: 500;
        font-size: 20px;
        line-height: 1;
        color: #212121;
        display: block;
        position: absolute;
        top: 20px;
        right: 20px;
    }
}

.sec-hero-questions {
    background-color: #ffdf3c;
    .header {
        font-weight: 400;
        font-size: 24px;
        letter-spacing: -1px;
        line-height: 1;
        color: #212121;
        display: block;
        transition: all 0.2s ease-in-out;
        @media screen and (max-width: 576px) {
            font-size: 24px;
            margin-top: 56px;
        }
        @media screen and (min-width: 576px) and (max-width: 768px) {
            font-size: 24px;
            margin-top: 48px;
        }
        @media screen and (min-width: 768px) and (max-width: 992px) {
            font-size: 28px;
        }
        @media screen and (min-width: 992px) and (max-width: 1200px) {
            font-size: 32px;
        }
    }
    .md-mb-4 {
        margin-bottom: 1.5rem;

        @media screen and (max-width: 768px) {
            margin-bottom: 0px !important;
        }
    }
    .subheader {
        font-size: 22px;
    }
    .question-header {
        font-weight: 400;
        font-size: 12px;
        letter-spacing: -0.5px;
    }
    .question-img {
        width: 100%;
        border-radius: 24px;
        border: 1px solid #212121;
        box-shadow: 2px 2px 0 #212121;
    }
    .subtitle {
        font-size: 15px;
    }
    .question-title {
        font-weight: 500;
        font-size: 22px;
        letter-spacing: -0.5px;
    }
    .card-category {
        margin: 4px;
        background-color: #ffffff;
        padding: 0.15rem 0.75rem 0.15rem 0.75rem;
        display: inline-block;
        font-size: 14px;
        border-radius: 36px;
        border: 1px solid #212121;
        box-shadow: 2px 2px 0 #212121;
        transition: box-shadow 0.1s, transform 0.1s ease-in-out; // added transform
        .question-count {
            font-size: 12px;
            color: #6c6c6c;
            position: relative;
            bottom: 1px;
        }

        &:hover {
            transform: translate(1px, 1px);
            box-shadow: 1px 1px 0 #000 !important;
            background: #ffffff;
            outline: none !important;
            border: 1px solid #212121;
          }
    }

    .category-highlight {
        background: #ffb066 !important;
        
    }
}

.text-left {
    text-align: left;
}

.text-fade-in-slide-up {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInSlideUp 0.7s ease-out 0.2s forwards;
  }
  
  @keyframes fadeInSlideUp {
    0% {
      opacity: 0;
      transform: translateY(20px); /* Start position */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* End position */
    }
  }

  .text-fade-out-slide-down {
    opacity: 1;
    transform: translateY(0);
    animation: fadeOutSlideDown 0.7s ease-out forwards;
  }

  @keyframes fadeOutSlideDown {
    0% {
      opacity: 1;
      transform: translateY(0); /* Start position */
    }
    100% {
      opacity: 0;
      transform: translateY(20px); /* End position */
    }
  }

  .image-fade-in-slide-up {
    opacity: 0;
    animation: fadeIn 0.7s ease-out 0.8s forwards;
  }

  @keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }

.link-gradient {
    background: linear-gradient(to right, #6371de, #bb8fc3, #fba5ae);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.sec-intro {
    background-color: white;
    margin-top: 80px;
    margin-bottom: 50px;
    position: relative;
    @media screen and (max-width: 450px) {
        margin-top: 60px;
    }
    @media screen and (min-width: 450px) and (max-width: 576px) {
        margin-top: 70px;
    }
    .intro-p {
        display: block;
        font-weight: 300;
        font-size: 18px;
        letter-spacing: -0.5px;
    }
    .intro-line {
        margin-top: 36px;
        margin-bottom: 36px;
    }
    .intro-anim {
        width: 100%;
        max-width: 600px;
    }
    .heading {
        font-size: 20px;
        font-weight: 300;
        display: block;
    }
    .subheading {
        font-size: 30px;
        font-weight: 500;
        display: block;
        @media screen and (max-width: 576px) {
            font-size: 24px;
        }
    }
    .info-box {
        background-color: #fbffcf;
        border-radius: 36px;
        margin-top: 48px;
        padding: 24px 0px;
        border: 1px solid #212121;
        box-shadow: 2px 2px 0 #212121;
    }
    .box-heading {
        font-weight: 300;
    }
    .box-footer {
        font-size: 20px;
        font-weight: 500;
    }
    .emoji-container {
        display: inline-block;
        border: 2px solid white;
        padding: 2px;
        border-radius: 50%;
    }
    .subcta-text {
        font-weight: 300;
        font-size: 16px;
        margin-top: 10px;
        letter-spacing: -0.5px;
        color: #212121;
        display: block;
        transition: all 0.2s ease-in-out;
        @media screen and (max-width: 576px) {
            margin-top: 20px;
            font-size: 14px;
        }
    }
    .omw-header {
        font-size: 23px;
        font-weight: 500;
        margin-bottom: 0px;
        @media screen and (max-width: 400px) {
            font-size: 24px;
        }
        @media screen and (min-width: 576px) and (max-width: 768px) {
            font-size: 21px;
        }
    }
    .omw-subheader {
        font-size: 20px;
        font-weight: 300;
    }
}

.anim-two {
    width: 100%;
}

.anim-three {
    width: 100%;
}

.swal2-title {
    font-weight: 300;
}

.noselect {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }


.home-section-header {
    font-family: 'Inter';
    font-weight: 300;
    font-size: 22px;
    letter-spacing: -1px;
    color: #212121;
    display: block;
    transition: all 0.2s ease-in-out;
}

.card {
    background-color: #ffffff;
    // padding: 2rem;
    padding: 1rem 1.5rem;
    border-radius: 36px;
    border: 1px solid #212121;
    box-shadow: 2px 2px 0 #212121;
}

.sec-why {
    padding-top: 80px;
    padding-bottom: 60px;
    background-color: #ffdf3c;
    // .card {
    //     background-color: #ffffff;
    //     padding: 2rem;
    //     border-radius: 36px;
    //     border: 1px solid #212121;
    //     box-shadow: 2px 2px 0 #212121;
    // }
    .title {
        font-weight: 900;
        font-size: 34px;
        letter-spacing: -1px;
    }
    .subtitle {
        display: block;
        font-weight: 300;
        font-size: 16px;
        letter-spacing: -0.5px;
    }
    .line-title {
        font-weight: 500;
        font-size: 20px;
        letter-spacing: -0.5px;
    }
    .line-subtitle {
        display: block;
        font-weight: 300;
        font-size: 16px;
        letter-spacing: -0.5px;
    }
    .anim-one {
        border-radius: 12px;
        width: 100%;
        max-width: 500px;
    }
}

.sec-money {
    background-color: #98bbff;
    padding-top: 48px;
    padding-bottom: 48px;
    // .card {
    //     background-color: #ffffff;
    //     padding: 2rem;
    //     border-radius: 36px;
    //     border: 1px solid #212121;
    //     box-shadow: 2px 2px 0 #212121;
    // }
    .subtitle {
        display: block;
        font-weight: 300;
        font-size: 16px;
        letter-spacing: -0.5px;
    }
}

.sec-journey {
    background-color: #98bbff;
    // padding-top: 48px;
    // padding-bottom: 48px;
    // .card {
    //     background-color: #ffffff;
    //     padding: 2rem;
    //     border-radius: 36px;
    //     border: 1px solid #212121;
    //     box-shadow: 2px 2px 0 #212121;
    // }
    .subtitle {
        display: block;
        font-weight: 300;
        font-size: 16px;
        letter-spacing: -0.5px;
    }
    .card {
        padding: 24px !important;
    }
    // .accordion {
    //     border: 0px !important;
    //     box-shadow: 0px 0px !important;
    //     .accordion-item {
    //         border: 1px black !important;
    //     }
    // }
}


.sec-pricing {
    .title {
        font-weight: 900;
        font-size: 36px;
        letter-spacing: -1px;
    }
    .bottom-text {
        font-weight: 300;
        font-size: 14px;
        letter-spacing: -0.5px;
        .gradient-link {
            font-weight: 500;
            background: linear-gradient(to right, #6371de, #bb8fc3, #fba5ae);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
        }
    }
    .pricing-col {
        @media screen and (max-width: 576px) {
            flex: 0 0 auto;
            width: 70%;
        }
        @media screen and (min-width: 576px) and (max-width: 768px) {
            flex: 0 0 auto;
            width: 50%;
        }

        @media screen and (min-width: 768px) and (max-width: 992px) {
            flex: 0 0 auto;
            width: 37%;
        }

        @media screen and (min-width: 992px) and (max-width: 1200px) {
            flex: 0 0 auto;
            width: 30%;
        }

        @media screen and (min-width: 1200px) {
            flex: 0 0 auto;
            width: 25%;
        }
    }
}

.pricing {
    .title {
        font-weight: 500;
        font-size: 22px;
        letter-spacing: -1px;
        color: #212121;
        display: block;
        transition: all 0.2s ease-in-out;
    }
    .subtitle {
        font-weight: 300;
        font-size: 14px;
        letter-spacing: -0.5px;
        color: #212121;
    }
    .super {
        font-size: 14px;
        font-weight: 300;
    }
    .price {
        font-size: 22px;
        font-weight: 300 !important;
    }
    .sub {
        margin-left: 6px;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: -0.5px;
    }
    .mt-1-5 { 
        margin-top: 0.35rem !important;
    }
    .icon-span {
        line-height: 1.1 !important;
    }
    .uil-check {
        color: #008001;
    }
    .features {
        font-weight: 300;
        font-size: 16px;
        letter-spacing: -0.5px;
    }
    .btn {
        font-weight: 500;
        background-color: #fbbf24;
        letter-spacing: -0px;
        border: 0px;
    }
}

.sec-faq {
    .faq-title {
        font-weight: 500;
        font-size: 18px;
        letter-spacing: -0.5px;
    }
    .faq-text {
        display: block;
        font-weight: 300;
        font-size: 16px;
        letter-spacing: -0.5px;
    }
    .faq-link {
        font-weight: 500;
    }
}

.sec-footercta {
    background-color: '#ffffff';
    border-radius: '36px';
    margin-top: '24px';
}

.sec-login {
    background-color: #ffdf3c;
    min-height: 80vh;
    height: auto;
    padding-top: 15vh;
    padding-bottom: 0px;
    background-size: cover;
    align-self: center;
    position: relative;
    background-position: center center;
    .card {
        background-color: #ffffff;
        padding: 2rem;
        border-radius: 36px;
        border: 1px solid #212121;
        box-shadow: 2px 2px 0 #212121;
    }
    .subtitle {
        display: block;
        font-weight: 300;
        font-size: 16px;
        letter-spacing: -0.5px;
    }
    .subcta-text {
        font-weight: 300;
        font-size: 16px;
        margin-top: 10px;
        letter-spacing: -0.5px;
        color: #212121;
        display: block;
        transition: all 0.2s ease-in-out;
        margin-left: 12.5%;
        width: 75%;
    }
}

.sec-footer-fill {
    min-height: 10vh;
}

.tw-icon {
    color: #1DA1F2 !important;
    font-size: 24px;
}

.signin-container {
    transition: opacity 0.2s ease-in-out 1s;
    opacity: 0;
}
.signin-container.show {
    opacity: 1;
}

.header-default-home {
    position: fixed;
    background-color: #ffdf3c;
    z-index: 10;
    width: 100%;
    height: 54px;
}

.options-container {
    position: relative;
    right: -36px;
    top: -24px;
}
.options-icon {
    position: absolute;
    right: 24px;
    top: 14px;
    color: #acacac !important;
    animation: fadeInAnim 0.4s ease-in-out;
}

ul.onboarding-ul {
    list-style-type: none; /* Remove default bullets */
    padding-left: 0; /* Optional: Remove padding if you want the bullets closer to the edge */
}

ul.onboarding-ul li::before {
    content: "\2023"; /* Triangle bullet character */
    padding-right: 10px; /* Space between the bullet and the text */
    font-weight: 500;
    /* Additional styling if needed */
}

ul.onboarding-ul li {
    line-height: 1.3;
    font-weight: 300;
}

.sec-question {
    background-color: #ffdf3c;
    height: auto; 
    padding-top: 40px;
    padding-bottom: 0px;
    background-size: cover;
    align-self: center;
    position: relative;
    background-position: center center;
    .question-header{
        font-weight: 400;
        font-size: 12px;
        letter-spacing: -0.5px;
    }
    .question-img {
        width: 100%;
        border-radius: 24px;
        border: 1px solid #212121;
        box-shadow: 2px 2px 0 #212121;
    }
    @media screen and (max-width: 576px) {
        padding-top: 64px;
        padding-bottom: 18px;
    }
}

.sec-answer {
    padding-top: 40px;
    background-color: #FFFFFF;
    .subtitle {
        font-weight: 300;
    }
    .ul-related {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
}

.anim-fade-in {
    animation: fadeInAnim 0.2s ease-in-out;
}

.anim-fade-out {
    animation: fadeOutAnim 0.2s ease-in-out;
}

.o-fade-out {
    // animation: fadeOutAnim 0.2s ease-in-out;
    opacity: 0;
    transition: opacity 0.2s ease-out;
}

.hidden-element {
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.visible-element {
    opacity: 1;
    transition: opacity 0.2s ease-in;
}

.full-height-container {
    box-sizing: border-box;
    display: grid;
    align-items: center;
    min-height: 100vh;
    height: auto;
    padding: 3rem 0;
}

.full-height-item {
    height: 100%;
}

.center-items {
    display: flex;
    align-items: center;
}

.shadow-box {
    box-shadow: 0 18px 36px -6px rgba(0,0,0,.08), 0 6px 12px -6px rgba(0,0,0,.08)
}


.sign-in {
    font-family: 'Inter';
    color: #1DA1F2 !important;
    font-style: normal;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 8px;
    padding: 6px;
    z-index: 999;
}

.redirection {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    color: #666666;
}


.align-self-start {
    align-self: start;
}

.colored-toast.swal2-icon-success {
    background-color: #a5dc86 !important;
  }
  
  .colored-toast.swal2-icon-error {
    background-color: #f27474 !important;
  }
  
  .colored-toast.swal2-icon-warning {
    background-color: #f8bb86 !important;
  }
  
  .colored-toast.swal2-icon-info {
    background-color: #26a7de !important;
  }
  
  .colored-toast.swal2-icon-question {
    background-color: #f8bb86 !important;
  }
  
  .colored-toast .swal2-title {
    color: white;
  }
  
  .colored-toast .swal2-close {
    color: white;
  }
  
  .colored-toast .swal2-html-container {
    color: white;
  }
  
  .fw-500 {
    font-weight: 500 !important;
  }

  .editable-action-container {
    // display: flex;
    // position: relative;
    position: absolute;
    background-color: white;
    border: 1px solid #b4b4b4;
    padding: 4px;
    z-index: 20;
    border-radius: 10px;
    .icon {
        margin-left: 4px;
        margin-right: 4px;
        display: inline;
        color: #666666;
    }
    .input {
        display: inline;
        height: 24px;
        width: 88%;
    }
  }

  .custom-shape-divider-top-1687718512 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1687718512 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 52px;
}

.custom-shape-divider-top-1687718512 .shape-fill {
    fill: #ffdf3c;
}

.custom-shape-divider-top-1687718512-2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1687718512-2 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 52px;
}

.custom-shape-divider-top-1687718512-2 .shape-fill {
    fill: #ffffff;
}

.custom-shape-divider-top-1687718512-3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    background-color: #ffdf3c;
}

.custom-shape-divider-top-1687718512-3 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 52px;
}

.custom-shape-divider-top-1687718512-3 .shape-fill {
    fill: #ffffff;
}

.custom-shape-divider-top-1687720502 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    // background-color: #98bbff;
    background-color: #ffffff;
}

.custom-shape-divider-top-1687720502 svg {
    position: relative;
    display: block;
    width: calc(158% + 1.3px);
    height: 39px;
    transform: rotateY(180deg);
}

.custom-shape-divider-top-1687720502 .shape-fill {
    fill: #ffdf3c;
}

.custom-shape-divider-top-1687720502-2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    background-color: #98bbff;
    // background-color: #ffffff;
}

.custom-shape-divider-top-1687720502-2 svg {
    position: relative;
    display: block;
    width: calc(158% + 1.3px);
    height: 39px;
    transform: rotateY(180deg);
}

.custom-shape-divider-top-1687720502-2 .shape-fill {
    fill: #ffffff;
}

.custom-shape-divider-top-1687720503 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    background-color: #FFFFFF;
}

.custom-shape-divider-top-1687720503 svg {
    position: relative;
    display: block;
    width: calc(158% + 1.3px);
    height: 39px;
    transform: rotateY(180deg);
}

.custom-shape-divider-top-1687720503 .shape-fill {
    fill: #ffdf3c;
}

.custom-shape-divider-top-1687729368 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1687729368 svg {
    position: relative;
    display: block;
    width: calc(291% + 1.3px);
    height: 48px;
}

.custom-shape-divider-top-1687729368 .shape-fill {
    fill: #ffffff;
}

.custom-shape-divider-top-1687778966 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1687778966 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 29px;
    transform: rotateY(180deg);
}

.custom-shape-divider-top-1687778966 .shape-fill {
    fill: #98bbff;
}

.custom-shape-divider-top-1689421199 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1689421199 svg {
    position: relative;
    display: block;
    width: calc(128% + 1.3px);
    height: 35px;
    transform: rotateY(180deg);
}

.custom-shape-divider-top-1689421199 .shape-fill {
    fill: #ffdf3c;
}

.custom-shape-divider-top-1687720503 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    background-color: #FFFFFF;
}

.custom-shape-divider-top-1687720503 svg {
    position: relative;
    display: block;
    width: calc(158% + 1.3px);
    height: 39px;
    transform: rotateY(180deg);
}

.custom-shape-divider-top-1687720503 .shape-fill {
    fill: #ffdf3c;
}

.custom-shape-divider-top-1692721691 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1692721691 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 45px;
}

.custom-shape-divider-top-1692721691 .shape-fill {
    fill: #ffdf3c;
}

.icon-box {
    padding: 0;
    height: 110px;
    width: 110px;
    border-radius: 36px;
    // border: 3px solid #000;
    margin: 0 auto;
    display: block;
    transform: rotate(-5deg);
    .emoji {
        position: absolute;
        height: 106px;
        width: 106px;
        text-align: center;
        line-height: 100px;
        font-size: 50px;
    }
}
.icon-box.reverse {
    transform: rotate(5deg);
}

.no-go-list {
    list-style: none;
    padding-left: 8px !important;
  }
  
  .no-go-list li::before {
    content: "❌";
    margin-right: 0.5em;
  }

  .unstyled-list {
    list-style: none;
    padding-left: 0px !important;
  }
  
  .unstyled-list li::before {
    content: "";
    margin-right: 0.5em;
  }


  /* Gradient Range Slider */
#range-slider-gradient {
    height: 24px;
    border-radius: 12px;
    background: #eaeaea;
  }
  
  #range-slider-gradient .range-slider__thumb {
    border: 6px solid #fff;
    box-sizing: border-box;
  }
  
  #range-slider-gradient .range-slider__thumb[data-lower] {
    background: #ffdf3c;
  }
  
  #range-slider-gradient .range-slider__thumb[data-upper] {
    background: #ffa63c;
  }
  
  #range-slider-gradient .range-slider__range {
    background: linear-gradient(to right, #ffdf3c, #ffa63c);
    background-size: 200% 100%;
    background-position: 50% 0;
  }
  
  #range-slider-gradient .range-slider__range[data-active],
  #range-slider-gradient
    .range-slider__thumb[data-active]
    ~ .range-slider__range {
    animation: move-bg 0.75s infinite linear;
  }
  
  @keyframes move-bg {
    0% {
      background-position: 50% 0;
    }
  
    25% {
      background-position: 100% 0;
    }
  
    50% {
      background-position: 50% 0;
    }
  
    75% {
      background-position: 0% 0;
    }
  
    100% {
      background-position: 50% 0;
    }
  }

  .accordion-body {
    font-weight: 300;
  }

  .accordion .accordion-item .accordion-button.collapsed {
    color: #212121 !important;
  }

  .masonry-grid {
    display: flex;
    margin-left: -30px; /* some gutter px */
    width: auto;
  }
  .masonry-grid_column {
    padding-left: 30px; /* some gutter px */
    background-clip: padding-box;
  }
  .masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 30px;
  }

  .question-container {
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
  }

  .question-container.show {
    opacity: 1
  }

  .vis-container {
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
  }

  .vis-container.show {
    opacity: 1
  }

  .bubble-pfp {
    display: inline;
    top: 12px;
    position: relative;

    img {
        border: 2px solid white;
        width: 32px;
        border-radius: 9999px;
        border-width: 2px;
    }
    p {
        color: black;
        display: inline;
    }
    .pos-0 {
        position: relative;
        right: -36px

    }
    .pos-1 {
        position: relative;
        right: -24px
    }
    .pos-2 {
        position: relative;
        right: -12px;
    }
    .pos-3 {
        position: relative;
        // right: -24px;
    }
    .pos-4 {
        position: relative;
        left: -12px;
    }
    .pos-count {
        position: relative;
        left: -24px;
    }
    .text-count {
        position: relative; 
        left: -51px;
        font-size: 10px;
        top: -1px;
    }
    .md-offset {
        display: inline;
        left: -40px;
        position: relative;
    }
  }

  .program-hero {
    line-height: 26px;
    top: 50px;
    text-align: left;

    @media screen and (max-width: 768px) {
        position: relative;
        top: -40px;
    }
    .left-10 {
        margin-left: 10px;
    }
  }

.bg-program {
    height: auto; 
    padding-top: 80px;
    padding-bottom: 40px;
    background-size: cover;
    align-self: center;
    position: relative;
    background-position: center center;

    @media screen and (max-width: 450px) {
        padding-top: 130px !important;
    }
    @media screen and (min-width: 450px) and (max-width: 576px) {
        padding-top: 130px !important;
    }
    @media screen and (min-width: 576px) and (max-width: 768px) {
        padding-top: 110px !important;
        padding-bottom: 0px;
    }
    .subtext {
        line-height: 1.35;
        @media screen and (max-width: 576px) {
            font-size: 22px;
        }
    }
    .indent {
        margin-left: 12px;
    }
    .sub-cta-text {
        line-height: 1.35;
        font-weight: 300;

        @media screen and (max-width: 576px) {
            font-size: 14px;
        }
    }
    .prog-header {
        font-weight: 700;
        font-size: 48px;
        letter-spacing: -2px;
        line-height: 1;
        color: #212121;
        display: block;
        transition: all 0.2s ease-in-out;
        @media screen and (max-width: 400px) {
            font-size: 38px;
        }
        @media screen and (min-width: 400px) and (max-width: 576px) {
            font-size: 42px;
        }
        @media screen and (min-width: 576px) and (max-width: 768px) {
            font-size: 34px;
        }
        @media screen and (min-width: 768px) and (max-width: 992px) {
            font-size: 42px;
        }
        @media screen and (min-width: 992px) and (max-width: 1200px) {
            font-size: 48px;
        }
    }
}

.bg-program-live {
    height: 520px; 
    padding-top: 80px;
    background-size: cover;
    align-self: center;
    position: relative;
    background-position: center center;

    @media screen and (max-width: 450px) {
        padding-top: 130px !important;
        height: 480px !important;
    }
    @media screen and (min-width: 450px) and (max-width: 576px) {
        padding-top: 160px !important;
        height: 540px !important;
    }
    @media screen and (min-width: 576px) and (max-width: 768px) {
        padding-top: 110px !important;
        height: 480px !important;
    }
    @media screen and (min-width: 768px) and (max-width: 830px) {
       height: 520px !important;
    }
    @media screen and (min-width: 830px) and (max-width: 992px) {
        height: 520px !important;
    }
    @media screen and (min-width: 992px) and (max-width: 1200px) {
        height: 520px !important;
    }
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
        height: 520px !important;
    }
    .subtext {
        line-height: 1.35;
        @media screen and (max-width: 400px) {
            font-size: 14px;
        }
        @media screen and (min-width: 400px) and (max-width: 576px) {
            font-size: 16px;
        }
    }
    .bio-text {
        line-height: 1.2;
        font-size: 16px;
        font-weight: 300;
    }
    .sub-cta-text {
        line-height: 1.35;
        font-weight: 300;

        @media screen and (max-width: 576px) {
            font-size: 14px;
        }
    }
    .prog-header {
        font-weight: 700;
        font-size: 48px;
        letter-spacing: -2px;
        line-height: 1;
        color: #212121;
        display: block;
        transition: all 0.2s ease-in-out;
        @media screen and (max-width: 400px) {
            font-size: 26px;
        }
        @media screen and (min-width: 400px) and (max-width: 576px) {
            font-size: 30px;
        }
        @media screen and (min-width: 576px) and (max-width: 768px) {
            font-size: 34px;
        }
        @media screen and (min-width: 768px) and (max-width: 992px) {
            font-size: 42px;
        }
        @media screen and (min-width: 992px) and (max-width: 1200px) {
            font-size: 48px;
        }
    }
    .cta-btn {
        margin-top: 0px !important;
    }
}

.bg-program-bio {
    min-height: 500px; 
    padding-top: 80px;
    background-size: cover;
    align-self: center;
    position: relative;
    background-position: center center;
    .subtext {
        line-height: 1.35;
        @media screen and (max-width: 400px) {
            font-size: 14px;
        }
        @media screen and (min-width: 400px) and (max-width: 576px) {
            font-size: 16px;
        }
    }
    .bio-text {
        line-height: 1.2;
        font-size: 16px;
        font-weight: 300;
    }
    .sub-cta-text {
        line-height: 1.35;
        font-weight: 300;

        @media screen and (max-width: 576px) {
            font-size: 14px;
        }
    }
    .prog-header {
        font-weight: 700;
        font-size: 48px;
        letter-spacing: -2px;
        line-height: 1;
        color: #212121;
        display: block;
        transition: all 0.2s ease-in-out;
        @media screen and (max-width: 400px) {
            font-size: 26px;
        }
        @media screen and (min-width: 400px) and (max-width: 576px) {
            font-size: 30px;
        }
        @media screen and (min-width: 576px) and (max-width: 768px) {
            font-size: 34px;
        }
        @media screen and (min-width: 768px) and (max-width: 992px) {
            font-size: 42px;
        }
        @media screen and (min-width: 992px) and (max-width: 1200px) {
            font-size: 48px;
        }
    }
    .bio-image {
        width: 120px;
        border-radius: 16px;
        border: 1px solid #212121;
        box-shadow: 1px 1px 0 #212121;
    }
}

.bg-program-onboarding {
    background-color: #ffdf3c;
    padding-top: 40px;
    background-size: cover;
    align-self: center;
    position: relative;
    background-position: center center;

    .omw-logo {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
    .cta-input {
        width: 100%;
        margin: 0px;
        border-radius: 20px;
        &:focus {
            box-shadow: 3px 3px 0 #212121;
            border: 1px solid #212121;
        }
    }
    .radio-input {
        box-shadow: 3px 3px 0 #212121;
        border: 1px solid #212121;
    }

    .left-10 {
        margin-left: 10px;
    }

    .personalize {
        background-color: #f6ff88;
        &:hover {
              background-color: #e6ef82;
          }
    }
    .onboarding-hero {
        line-height: 26px;
        top: 50px;
        text-align: left;
    }
    .subtext {
        font-size: 20px;
    }
    .card-text {
        font-size: 20px;
        font-weight: 300;
        @media screen and (max-width: 400px) {
            font-size: 16px;
        }
        @media screen and (min-width: 400px) and (max-width: 576px) {
            font-size: 16px;
        }
        @media screen and (min-width: 576px) and (max-width: 768px) {
            font-size: 18px;
        }
        @media screen and (min-width: 768px) and (max-width: 992px) {
            font-size: 18px;
        }
        @media screen and (min-width: 992px) and (max-width: 1200px) {
            font-size: 18px;
        }
        @media screen and (min-width: 1200px) and (max-width: 1400px) {
            font-size: 18px;
        }
    }
    .onboarding-ul {
        font-size: 18px;
        @media screen and (max-width: 576px) {
            font-size: 14px;
        }
        @media screen and (min-width: 576px) and (max-width: 992px) {
            font-size: 16px;
        }
    }
    .radio-group {
        font-weight: 300;
        .radio-subtext {
            font-size: 12px;
            margin-left: 2px;
        }
    }
    /* Hide the default radio button */
    .radio-group input[type="radio"] {
        display: none;
      }
    
      /* Style for custom radio button */
      .radio-group .radio-btn {
        height: 20px;
        width: 20px;
        top: -2px;
        border-radius: 20px;
        background: white;
        box-shadow: 3px 3px 0 #212121;
        border: 1px solid #212121;
        display: inline-block;
        position: relative;
        margin-right: 10px;
        vertical-align: middle;
      }
    
      /* Style when the radio button is checked */
      .radio-group input[type="radio"]:checked + .radio-btn {
        border-color: black;
      }
    
      /* Inner circle for checked state */
      .radio-group input[type="radio"]:checked + .radio-btn::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        background: black;
        border-radius: 50%;
      }
}

.bg-program-content {
    height: auto;
    padding-bottom: 40px;
    padding-top: 60px;
    background-size: cover;
    align-self: center;
    position: relative;
    background-position: center center;
    @media screen and (max-width: 768px) {
        padding-top: 90px;
    }
    .omw-logo {
        @media screen and (min-width: 576px) and (max-width: 768px) {
            width: 60px !important;
        }
        @media screen and (min-width: 768px) and (max-width: 830px) {
            width: 100px !important;
        }
        @media screen and (min-width: 830px) and (max-width: 992px) {
            width: 120px;
        }
    }
    .container {
        @media screen and (max-width: 576px) {
            max-width: 480px !important;
        }
        @media screen and (min-width: 576px) and (max-width: 768px) {
            max-width: 700px !important;
        }
        @media screen and (min-width: 768px) and (max-width: 830px) {
            max-width: 700px !important;
        }
        @media screen and (min-width: 830px) and (max-width: 992px) {
            max-width: 760px !important;
        }
    }
    .session-selector {
        @media screen and (max-width: 768px) {
            padding-left: 0rem;
            padding-right: 0rem;
        }
    }
    .program-hero {
        top: 0px;
    }

    /* Reposition the default arrows */
    .slick-prev {
        position: absolute;
        top: -18%; /* Center vertically */
        left: 10%; /* Adjust to move the arrows further left */
        transform: rotate(90deg);
        z-index: 1;
        @media screen and (max-width: 576px) {
            left: 48%;
            top: -12%;
            // transform: translate(-50%, -50%);
        }
        @media screen and (min-width: 577px) and (max-width: 768px) {
            left: 48%;
            top: -12%;
            // transform: translate(-50%, -50%);
        }
    }

    .slick-next {
        position: absolute;
        top: 91%; /* Center vertically */
        left: 10%; /* Adjust to move the arrows further left */
        transform: rotate(90deg);
        z-index: 1;
        @media screen and (max-width: 576px) {
            left: 48%;
            top: 97%;
            // transform: translate(-50%, -50%);
        }
        @media screen and (min-width: 577px) and (max-width: 768px) {
            left: 48%;
            top: 97%;
            // transform: translate(-50%, -50%);
        }
    }
  
  /* Ensure arrows are displayed */
  .slick-prev:before, .slick-next:before {
    color: #212121; /* Or any color you prefer */
    font-size: 22px !important;
  }
    .stepper-column {
        font-weight: 300;
        font-size: '20px';
    }
    .stepper {
        font-family: 'Readex Pro';
    }
    .stepper span {
        // Text below the step bubble
        margin-top: 6px;
        @media screen and (max-width: 450px) {
            font-size: 10px;
        }
        @media screen and (min-width: 450px) and (max-width: 576px) {
            font-size: 12px;
        }
        @media screen and (min-width: 577px) and (max-width: 768px) {
            font-size: 14px;
        }
        white-space: pre-line;
    }
    .stepper .step span {
        // Text inside the step bubble
        margin-top: 0px;
    }
    .stepper-short-title {
        margin-top: auto;
        margin-bottom: auto;
        // width: 10%;
        font-size: 18px;
        font-weight: 500;
    }
    .stepper-title-inner {
        margin-top: auto;
        margin-bottom: auto;
        width: 25%;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.2;
        @media screen and (max-width: 768px) {
            display: none;
            // width: 100%;
        }
    }

    .stepper-title-outer {
        display: none;
        @media screen and (max-width: 768px) {
            margin-top: 4px;
            margin-bottom: 14px;
            width: 100%;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.2;
            display: block;
        }
    }

    .stepper-buffer {
        @media screen and (max-width: 768px) {
            display: none;
        }
        @media screen and (min-width: 768px) {
            display: block !important;
            margin-top: 1px;
        }

    }
    .step {
        box-shadow: 3px 3px 0 #212121;
        border: 1px solid #212121;
    }
    .step-link {
        padding: 10px;
    }
    .stepper-label-maybe {
        background-color: red;
    }
    .subtext-content {
        font-size: 14px;
    }
    .content-external {
        font-weight: 500;
        margin-left: 8px;
        margin-right: 8px;
    }
    .embed-responsive-item {
        border-radius: 18px;
        box-shadow: 3px 3px 0 #212121;
        border: 1px solid #212121;
    }
    .tally-frame {
        width: 100%;
        // height: 367px; // I'm sure this is dynamic and I need to fix this
        height: auto;
        overflow: auto;
        padding: 4px;
        iframe {
            border-radius: 24px;
            box-shadow: 3px 3px 0 #212121;
            border: 1px solid #212121;
        }
        .overlay {
            position: sticky;
            bottom: -4px;
            left: 0;
            right: 0;
            height: 50px; /* Height of the fading effect */
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 223, 60, 1));
            pointer-events: none; /* Allows interaction with the iframe underneath */
        }
    }
    .slick-vertical .slick-slide {
        border-bottom: 1.5px solid transparent !important;
    }
}

.async {
    height: 320px; 

    @media screen and (max-width: 450px) {
        padding-top: 130px !important;
        height: 280px !important;
    }
    @media screen and (min-width: 450px) and (max-width: 576px) {
        padding-top: 160px !important;
        height: 340px !important;
    }
    @media screen and (min-width: 576px) and (max-width: 768px) {
        padding-top: 110px !important;
        height: 280px !important;
    }
    @media screen and (min-width: 768px) and (max-width: 830px) {
       height: 320px !important;
    }
    @media screen and (min-width: 830px) and (max-width: 992px) {
        height: 320px !important;
    }
    @media screen and (min-width: 992px) and (max-width: 1200px) {
        height: 320px !important;
    }
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
        height: 320px !important;
    }
}


.omw-logo {
    width: 160px;
    top: 20px;
    left: 40px;
    position: absolute;
    @media screen and (max-width: 450px) {
        width: 50px !important;
    }
    @media screen and (min-width: 450px) and (max-width: 576px) {
        width: 60px !important;
    }
    @media screen and (min-width: 576px) and (max-width: 768px) {
        width: 120px !important;
    }
    @media screen and (min-width: 768px) and (max-width: 830px) {
        width: 160px !important;
    }
    @media screen and (min-width: 830px) and (max-width: 992px) {
        width: 160px;
    }
}

.sec-program-story {
    background-color: white;
    margin-top: 50px;
    margin-bottom: 50px;
    position: relative;
    @media screen and (max-width: 450px) {
        margin-top: 60px;
    }
    @media screen and (min-width: 450px) and (max-width: 576px) {
        margin-top: 70px;
    }
    .subtext {
        font-weight: 300;
        font-size: 20px;
    }  
    .bold {
        font-weight: 500;
    }
    .dotted-line:after {
        content:"";
        position: inherit;
        display: inline-flex;
        z-index: 100;
        height: 26px;
        top: 0;
        bottom: 0;
        left: 50%;
        border-left: 2px dotted #444; /*change these values to suit your liking*/
    }
    .story-profile {
        width: 120px;
        border-radius: 24px;
    }
}

.sec-program-agenda {
    padding-top: 50px;
    padding-bottom: 60px;
    background-color: #ffdf3c;
    .card {
        padding: 2rem 2rem !important;
    }
    .title {
        font-weight: 900;
        font-size: 1.5rem;
        letter-spacing: -1px;
    }
    .subtitle {
        display: block;
        font-weight: 300;
        font-size: 16px;
        letter-spacing: -0.5px;
    }
    .emoji {
        font-size: 24px;
    }
    .emoji-text {
        position: relative;
        top: -2px;
        left: 4px;
        font-size: 18px;
        font-weight: 300;
    }
}

.sec-program-profiles {
    background-color: white;
    margin-top: 70px;
    margin-bottom: 50px;
    position: relative;
    @media screen and (max-width: 450px) {
        margin-top: 60px;
    }
    @media screen and (min-width: 450px) and (max-width: 576px) {
        margin-top: 70px;
    }
    .fw-margin {
        margin-left: 20px;
        margin-right: 20px;
    }
    .bio-card {
        background-color: #ffffff;
        padding: 1rem;
        width: 130px;
        border-radius: 16px;
        border: 1px solid #212121;
        box-shadow: 2px 2px 0 #212121;
        cursor: pointer;
        transition: box-shadow 0.1s, transform 0.1s ease-in-out; // added transform
        .bio-image {
            border-radius: 32px;
            border: 1px solid #212121;
            width: 100%;
        }
        .bio-text {
            font-size: 12px;
            color: black;
        }
        
    }
    .odd {
        transform: rotate(-2deg);
        &:hover {
            transform: rotate(-2deg) translate(2px, 2px);
            box-shadow: 1px 1px 0 #000 !important;
            color: #212121;
            outline: none !important;
            border: 1px solid #212121;
        }
    }
    .even {
        margin-top: 30px;
        transform: rotate(2deg);
        &:hover {
            transform: rotate(2deg) translate(2px, 2px);
            box-shadow: 1px 1px 0 #000 !important;
            color: #212121;
            outline: none !important;
            border: 1px solid #212121;
        }
    }
}

.sec-program-desc {
    background-color: white;
    margin-top: 100px;
    padding-top: 30px;
    margin-bottom: 0px;
    position: relative;
    @media screen and (max-width: 450px) {
        margin-top: 60px;
    }
    @media screen and (min-width: 450px) and (max-width: 576px) {
        margin-top: 70px;
    }
    .intro-p {
        display: block;
        font-weight: 300;
        font-size: 18px;
        letter-spacing: -0.5px;
    }
    .intro-line {
        margin-top: 36px;
        margin-bottom: 36px;
    }
    .intro-anim {
        width: 100%;
        max-width: 600px;
    }
    .heading {
        font-size: 20px;
        font-weight: 300;
        display: block;
    }
    .subheading {
        font-size: 30px;
        font-weight: 500;
        display: block;
        @media screen and (max-width: 576px) {
            font-size: 24px;
        }
    }
    .subtitle {
        font-weight: 300;
    }
    .info-box {
        background-color: #fbffcf;
        border-radius: 36px;
        margin-top: 48px;
        padding: 24px 0px;
        border: 1px solid #212121;
        box-shadow: 2px 2px 0 #212121;
    }
    .box-heading {
        font-weight: 300;
    }
    .box-footer {
        font-size: 20px;
        font-weight: 500;
    }
    .emoji-container {
        display: inline-block;
        border: 2px solid white;
        padding: 2px;
        border-radius: 50%;
    }
    .subcta-text {
        font-weight: 300;
        font-size: 16px;
        margin-top: 10px;
        letter-spacing: -0.5px;
        color: #212121;
        display: block;
        transition: all 0.2s ease-in-out;
        @media screen and (max-width: 576px) {
            margin-top: 20px;
            font-size: 14px;
        }
    }
    .program-switch {
        font-size: 12px;
    }
    .accordion-helpfuls {
        width: 100%;
    }
}

.bg-yellow {
    background-color: #ffdf3c;
}

.sec-program-end {
    background-color: white;
    margin-top: 40px;
    padding-top: 30px;
    margin-bottom: 50px;
    position: relative;
    @media screen and (max-width: 450px) {
        margin-top: 60px;
    }
    @media screen and (min-width: 450px) and (max-width: 576px) {
        margin-top: 70px;
    }
    .subtitle {
        font-weight: 300;
    }
}

.sec-program-sched {
    padding-top: 80px;
    padding-bottom: 60px;
    background-color: #ffdf3c;
    .card {
        padding: 2rem 2rem !important;
    }
    .title {
        font-weight: 900;
        font-size: 1.5rem;
        letter-spacing: -1px;
    }
    .subtitle {
        display: block;
        font-weight: 300;
        font-size: 16px;
        letter-spacing: -0.5px;
    }
    .line-title {
        font-weight: 500;
        font-size: 20px;
        letter-spacing: -0.5px;
    }
    .line-subtitle {
        display: block;
        font-weight: 300;
        font-size: 16px;
        letter-spacing: -0.5px;
    }
    .anim-one {
        border-radius: 12px;
        width: 100%;
        max-width: 500px;
    }
}

.prog-cta-header {
    font-size: 26px;
    font-weight: 400;
    display: block;
}


.lets-go-cta {
    font-weight: 500;
    // background-color: #fbbf24;
    background-color: #a0ffae;
    letter-spacing: -0px;
    width: 100%;

    line-height: 1.1em;
    border: 1px solid #212121;
    padding: 10px 20px;
    font-size: 1.3em;
    box-shadow: 3px 3px 0 #212121;
    border-radius: 5px;
    // background: #ffb065;
    color: #212121;
    display: inline-block;
    cursor: pointer;
    position: relative;
    outline: none !important;

    transition: box-shadow 0.1s, transform 0.1s ease-in-out; // added transform
  
    &:hover {
      transform: translate(2px, 2px);
      box-shadow: 1px 1px 0 #000 !important;
    //   background: #ffb065;
        background-color: #8ce899;
      color: #212121;
      outline: none !important;
      border: 1px solid #212121;
    //   transition: box-shadow 0.3s, top 0.3s, left 0.3s ease-in-out;
    }
    &:disabled {
        background: #8ce899;
    }
    &:focus {
        outline: none !important;
        box-shadow: 1px 1px 0 #000 !important;
    }
    &:active {
        background-color: #8ce899 !important;
        color: #212121 !important;
        outline: none !important;
        box-shadow: 1px 1px 0 #000 !important;
    }
    &:focus-visible {
        background-color: #8ce899 !important;
        color: #212121 !important;
        border: 1px solid #212121 !important;
        box-shadow: 3px 3px 0 #212121 !important;
    }

    @media screen and (max-width: 576px) {
        // max-width: 480px !important;
        margin-top: 0px;
    }
    // @media screen and (min-width: 576px) and (max-width: 768px) {
    //     max-width: 540px !important;
    // }
    // @media screen and (min-width: 768px) and (max-width: 830px) {
    //     max-width: 700px !important;
    // }
    @media screen and (min-width: 830px) and (max-width: 992px) {
        max-width: 760px !important;
    }
    // @media screen and (min-width: 992px) and (max-width: 1280) {
    //     max-width: 760px !important;
    // }
}

.sec-program-faq {
    background-color: #98bbff;
    padding-top: 48px;
    padding-bottom: 48px;
    // .card {
    //     background-color: #ffffff;
    //     padding: 2rem;
    //     border-radius: 36px;
    //     border: 1px solid #212121;
    //     box-shadow: 2px 2px 0 #212121;
    // }
    .mini-title {
        font-size: 16px;
    }
    .subtitle {
        display: block;
        font-weight: 300;
        font-size: 16px;
        letter-spacing: -0.5px;
    }

    .card {
        padding: 2rem 2rem !important;
    }
}

.omw-bordered {
    width: 100%;
    border-radius: 16px;
    box-shadow: 3px 3px 0 #212121;
    transition: box-shadow 0.1s, transform 0.1s ease-in-out; // added transform
    &:hover {
        transform: translate(2px, 2px);
        box-shadow: 1px 1px 0 #000 !important;
      }
}