//Fonts
// @import "custom/fonts/fonts";

//Core files
// @import "./node_modules/bootstrap/scss/functions";
// @import "./node_modules/bootstrap/scss/variables";
@import "variables";
// @import "./node_modules/bootstrap/scss/mixins.scss";


// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/footer";

// Components
// @import "custom/components/avatar";
@import "custom/components/accordion";
// @import "custom/components/preloader";
// @import "custom/components/forms";
// @import "custom/components/breadcrumb";
// @import "custom/components/buttons";
// @import "custom/components/badge";
// @import "custom/components/backgrounds";
// @import "custom/components/alerts";
// @import "custom/components/dropdown";
// @import "custom/components/blockquote";
// @import "custom/components/shadow";
// @import "custom/components/carousel";
// @import "custom/components/reboot";
// @import "custom/components/nav";
// @import "custom/components/text";
// @import "custom/components/progress";
// Pages
// @import "custom/pages/blog-job";
// @import "custom/pages/contact";
// @import "custom/pages/countdown";
// @import "custom/pages/cta";
// @import "custom/pages/features";
// @import "custom/pages/home";
// @import "custom/pages/price";
// @import "custom/pages/team";
// @import "custom/pages/testi";
// @import "custom/pages/user";
// @import "custom/pages/work";
// @import "custom/pages/helper";
// @import "custom/pages/switcher";



.text-transparent {
   color: transparent;
}

.font-extrabold {
   font-weight: 800;
}

.bg-clip-text {
   -webkit-background-clip: text;
   background-clip: text;
}

.bg-gradient-to-br {
   background-image: linear-gradient(to bottom right, var(--tw-gradient-from), var(--tw-gradient-stops), var(--tw-gradient-to));
}

.from-primary {
   --tw-gradient-from: #fca019;
   --tw-gradient-stops: rgba(252,160,25,0);
}

.via-primary {
   --tw-gradient-stops: #fca019;
}

.to-secondary {
   // --tw-gradient-to: #d4370a;
   --tw-gradient-to: #be6907;
}

@media (max-width:576px) {
   .px-sm-0 {
      padding-right: 0 !important;
      padding-left: 0 !important
   }

   .px-sm-1 {
      padding-right: .25rem !important;
      padding-left: .25rem !important
   }

   .px-sm-2 {
      padding-right: .5rem !important;
      padding-left: .5rem !important
   }

   .px-sm-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important
   }

   .px-sm-4 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important
   }

   .px-sm-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important
   }
}

@font-face {
   font-family: 'Readex Pro';
   src: url('../fonts/ReadexPro-ExtraLight.ttf') format('truetype');
   font-weight: 200; /* Regular weight */
   font-style: normal;
}

@font-face {
   font-family: 'Readex Pro';
   src: url('../fonts/ReadexPro-Light.ttf') format('truetype');
   font-weight: 300; /* Regular weight */
   font-style: normal;
}

@font-face {
   font-family: 'Readex Pro';
   src: url('../fonts/ReadexPro-Medium.ttf') format('truetype');
   font-weight: 500; /* Regular weight */
   font-style: normal;
}

@font-face {
   font-family: 'Readex Pro';
   src: url('../fonts/ReadexPro-Bold.ttf') format('truetype');
   font-weight: 700; /* Regular weight */
   font-style: normal;
}